import React from 'react';
import { data } from "../data/data.js";

const Work = () => {
  // projects file
  const project = data;

  return (
    <div name='work' className='w-full min-h-screen text-gray-300 bg-[#0a192f]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8 mt-40'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>
            Personal Projects
          </p>
          <p className='py-6'>// Check out some of my recent work</p>
        </div>

        {/* container for projects */}
        <div className="w-full overflow-hidden">
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {/* Grid Item */}
            {project.map((item, index) => (
              <div
                key={index}
                style={{ backgroundImage: `url(${item.image})` }}
                className="shadow-lg shadow-[#040c16] mx-auto w-full max-w-[480px] rounded-md flex flex-col justify-end 
                           items-center h-80 md:h-96 lg:h-[22rem] bg-cover bg-center relative 
                           transition-transform duration-300 ease-in-out transform hover:scale-105"
              >
                {/* Content over the bottom half of the image */}
                <div className="absolute bottom-0 left-0 w-full bg-black bg-opacity-75 p-2 sm:p-4">
                  <span className="text-base sm:text-lg font-bold text-white tracking-wider block">
                    {item.name}
                  </span>
                  {/* Conditionally render tech stack */}
                  {item.id !== 3 && (
                    <span className="text-xs sm:text-sm font-bold text-white text-left block mt-1">
                      Tech Stack: {item.skills}
                    </span>
                  )}
                  <p className="text-xs sm:text-sm text-white mt-2 text-left inline">
                    {item.description}
                    <span className="text-red-500 font-bold ml-2 inline">
                      <a href={item.github} target="_blank" rel="noopener noreferrer">
                        Link Here
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
