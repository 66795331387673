import mern from '../assets/projects/mern.jpg'
import inf from '../assets/projects/inf.jpg'
import figma from '../assets/projects/figma.png'
import spring from '../assets/projects/spring.jpg'
export const data=[
    {
        id:1,
        name:"Study Space Finder",
        skills:"React, Antd, Node, MongoDB, Redux, Stripe API",
        image:mern,
        github:"https://github.com/aziz-vohra/Study-Space-Finder",
        description:"This project helps Boston students find and reserve study spaces, managing seating and pricing with secure authentication and seamless API interactions."
    },
    {
        id:2,
        name:"Cloud Based User Application",
        skills:"AWS, Terraform, GitHub, Node, PostgreSQL",
        image:inf,
        github:"https://github.com/AzizVohra/WebApp",
        description:"This project built a cloud-based app using AWS and Terraform, integrating EC2, RDS, and S3, managing user data and images with scalable infrastructure and CI/CD pipelines."
    },
    {
        id:3,
        name:"Design portfolio",
        image:figma,
        github:"https://www.figma.com/proto/fKvjwXDgpIlCXk3tZ8cxwd/Temu?node-id=0-1&t=WoeX762Uzo38jAYw-1",
        description:"This project involved designing mobile applications, transitioning from low-fidelity wireframes to high-fidelity prototypes, to create detailed and functional app designs."
    },
    {
        id:4,
        name:"Course Registration Web Application ",
        skills:"Java, JSP, Spring Boot, Hibernate, MySQL",
        image:spring,
        github:"https://github.com/aziz-vohra/course_registration/tree/master",
        description:"This project involved creating a web application for course management, allowing advisors to manage courses and students to register or drop them."
    }
    
]
