import React from 'react';
import cert from '../assets/cert.jpeg'; // Adjust the path according to your project structure

const Certificate = () => {
  return (
    <div name='certificate' className='w-full h-screen bg-[#0a192f] text-gray-300'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className='sm:text-right pb-8 pl-4'>
            <p className='text-4xl font-bold inline border-b-4 border-pink-600'>
              Certification
            </p>
          </div>
          <div></div>
        </div>
        <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
          <div className='sm:text-right text-4xl font-bold'>
            <p>AWS Certified Solutions Architect - Associate</p>
          </div>
          <div className='flex flex-col items-center'>
            <img
              className='hover:scale-110 transition-transform duration-300'
              src={cert}
              alt="Certification icon"
              style={{
                width: '300px',
                height: '300px',
                marginLeft: '-15px',
              }}
            />
            <a
              href='https://www.credly.com/badges/dd0ca6e5-156d-4f0b-a9b2-6a93cf247963/public_url'
              target='_blank'
              rel='noopener noreferrer'
              className='text-white hover:underline mt-5'
              style={{
                marginTop: '20px',
                textAlign: 'center',
              }}
            >
              View Certification
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
