import React from 'react'


function Workexp() {
  return (
  <div name='workexp' id="workexperience" className="sectionClass text-gray-300 bg-[#0a192f]">
    <div className="row ">
    <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>
           Work Experience
    </p>
      <div className="fullWidth eight columns">
        <ul className="cbp_tmtimeline">
           
          <li>
            <div className="cbp_tmicon cbp_tmicon-mail nasuni">
              <i className="faPra" />
            </div>
            <div className="cbp_tmlabel wow fadeInRight animated">
              <h3>Software Engineering Intern</h3>
              <h4>
                <i className="fa fa-flag" />
                Nasuni Corporation, Boston, US
              </h4>
              <div className="date">
                <i className="fa fa-calendar" />
                June 2023 - December 2023
              </div>
              <p className="projectParagraph">
              I worked with the Nasuni Orchestration Center (NOC) team at Nasuni Corporation to automate and optimize various
              aspects of our AWS-based infrastructure. I developed and executed infrastructure 
               automation scripts, streamlined the setup of the NOC maintenance website, 
               and automated migration validation processes in our DEV CD pipeline. 
               Additionally, I created a cron job for detecting AWS secret rotations and
               addressed low disk space issues in production, all contributing to enhanced
               system efficiency and reliability.

              </p>
            </div>
          </li>

          
          
          <li>
            <div className="cbp_tmicon cbp_tmicon-phone deloitte">
              <i className="faPra" />
            </div>
            <div className="cbp_tmlabel wow fadeInRight animated">
              <h3>Software Engineer (Analyst)</h3>
              <div className="date">
                <i className="fa fa-calendar" />
                March 2021 - April 2022
              </div>
              <h4>
                <i className="fa fa-flag" />
                Deloitte India, Mumbai,India
              </h4>
              <p className="projectParagraph">
           I developed user interface to manage and view transactions for an Indian Private Bank
           using React and its libraries and got involved
           in frequent client interaction to improve customer satisfaction and
           reduced data fetching time by 20% after deployment. I also employed techniques
           like code splitting and tree shaking to minimize
           frontend bundle sizes to speed up website load times.
              </p>
            </div>
          </li>
          
          
          <li>
            <div className="cbp_tmicon cbp_tmicon-screen o9">
              <i className="faPra " />
            </div>
            <div className="cbp_tmlabel wow fadeInRight animated">
              <h3>Software Engineer</h3>
              <h4>
                <i className="fa fa-flag" />
                o9 Solutions, Inc, Bengaluru, India
              </h4>
              <div className="date">
                <i className="fa fa-calendar" />
                December 2020 - March 2021
              </div>
              <p className="projectParagraph">
                I designed and built an interactive and responsive widget for the o9 AI Platform,
                and addressed issues with existing platform-owned widgets. Additionally,
                I implemented a new data model pattern to optimize data fetching,
                resulting in a 30% improvement in API round trip time. These efforts enhanced
                the functionality and performance of the platform.
              </p>
            </div>
          </li>


          <li>
            <div className="cbp_tmicon cbp_tmicon-mail hp">
              <i className="faPra" />
            </div>
            <div className="cbp_tmlabel wow fadeInRight animated">
              <h3>Software Developer Intern</h3>
              <h4>
                <i className="fa fa-flag" />
                HP Inc, Bengaluru, India
              </h4>
              <div className="date">
                <i className="fa fa-calendar" />
                January 2020 - July 2020
              </div>
              <p className="projectParagraph">
            I designed and implemented a user interface for the business
            operations team at HP US stores to streamline the process of
            entering price and promotion data. By using this interface,
            the stores were able to reduce their reliance on ServiceNow
            tickets and increase the rate at which data was entered by 50%,
            leading to an increase in sales revenue.

              </p>
            </div>
          </li>
          
          
          <li>
            <div className="cbp_tmicon cbp_tmicon-phone cycops">
              <i className="faPra" />
            </div>
            <div className="cbp_tmlabel wow fadeInRight animated">
              <h3>Cyber Security Intern</h3>
              <h4>
                <i className="fa fa-flag" />
                CyCOPS India Pvt. Ltd, Hyderabad, India
              </h4>

              <div className="date">
                <i className="fa fa-calendar" />
                June 2019 - July 2019
              </div>
              <p className="projectParagraph">
              I helped schedule regular scans using various vulnerability detection tools
              to identify and track vulnerabilities in clients' infrastructure.
              This helped prioritize and address vulnerabilities promptly, improving
              the organization's security and protecting its data and assets.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  )
}

export default Workexp
